import React, { Suspense, useRef } from 'react'
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Fallback } from '../index'
import Shape from './02/Shape'
import lerp from 'lerp'

extend({ OrbitControls })

const Startup = () => {
  const { camera } = useThree()

  const ref = useRef()
  useFrame(() => {
    ref.current.material.opacity = lerp(ref.current.material.opacity, 0, 0.01)
    camera.zoom = lerp(camera.zoom, 1.2, 0.05)
    if (Math.abs(camera.zoom - 100) > 0.001) camera.updateProjectionMatrix()
  })
  return (
    <mesh ref={ref} position={[0, 0, 0]} scale={[1, 1, 1]}>
      <planeBufferGeometry attach="geometry" />
      <meshBasicMaterial attach="material" color="#0e0e0f" transparent opacity={0} />
    </mesh>
  )
}

export default () => {
  const isSSR = typeof window === 'undefined'
  return (
    <>
      <Canvas shadowMap camera={{ position: [0, 0, 100], near: 0.01, far: 1000, fov: 75 }}>
        {/* <Controls enablePan={false} enableZoom={false} enableDamping dampingFactor={0.5} rotateSpeed={1} /> */}
        {!isSSR && (
          <Suspense fallback={<Fallback />}>
            <Shape />
            <Startup />
          </Suspense>
        )}
      </Canvas>
    </>
  )
}
