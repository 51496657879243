import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const base = {
  align: 'left',
  background: 'none',
  color: 'white',
  line: 1,
  link: 'underline',
  hover: 'none',
  margin: 'none',
  max: 'auto',
  padding: 'none',
  size: '1.2rem',
  weight: 'normal',
  spacing: '0',
  transform: 'none',
}

// font size for cera
const c1 = css`
  font-size: 2rem;
  line-height: 1.15;
  @media screen and (min-width: 1025px) {
    font-size: 2rem;
  }
`

// font size for guggeli
const g1 = css`
  font-size: 2rem;
  line-height: 1.2;
  @media screen and (min-width: 1025px) {
    font-size: 2.2rem;
    line-height: 1.15;
  }
`

const StyledText = styled.p`
	text-align: ${({ align }) => (align ? align : base.align)};
	color: ${({ color }) => (color ? color : base.color)};
	background: ${({ background }) => (background ? background : base.background)};
  letter-spacing: ${({ spacing }) => (spacing ? spacing : base.spacing)};
  margin: ${({ margin }) => (margin ? margin : base.margin)};
  font-family: ${({ font }) => (font === 'guggeli' ? 'Guggeli-rounded' : 'Cera')};
	font-weight: ${({ weight }) => (weight ? weight : base.weight)} !important;
	max-width: ${({ max }) => (max ? max : base.max)};
  text-transform: ${({ transform }) => (transform ? transform : base.transform)};
	${({ size }) => size === 'c1' && c1}
  ${({ size }) => size === 'g1' && g1}

  ${({ tag }) =>
    (tag =
      'span' &&
      css`
        display: inline-block;
      `)}

	${({ link }) =>
    link &&
    css`
      text-decoration: ${link};
    `}
    ${({ line }) =>
      line &&
      css`
        line-height: ${line}!important;
      `}
    & a {
        color: inherit;
        text-decoration: ${({ link }) => (link ? link : base.link)};
    }
    & a:hover {
        color: ${({ hoverColor }) => (hoverColor ? hoverColor : 'inherit')};;
        text-decoration: ${({ hover }) => (hover ? hover : base.hover)};
    }
    + blockquote, + iframe, + code {
      margin-top: 6rem;
    }

    + p, + ul {
      margin-top: 1.5rem;
      @media screen and (min-width: 721px) {
        margin-top: 3rem;
      }
    }

    + h2, + h3, + h4 {
      margin-top: 3rem;
      @media screen and (min-width: 721px) {
        margin-top: 6rem;
      }
    }

    + div {
      margin-top: 0rem;
    }
    }
`

export const Text = ({
  size = 'g1',
  tag = 'p',
  color,
  spacing,
  hover,
  link,
  line,
  weight,
  hoverColor,
  font = 'guggeli',
  transform,
  ...rest
}) => {
  return (
    <StyledText
      {...rest}
      as={tag}
      size={size}
      line={line}
      hoverColor={hoverColor}
      weight={weight}
      spacing={spacing}
      hover={hover}
      color={color}
      font={font}
      transform={transform}
      link={link}
    />
  )
}

Text.propTypes = {
  size: PropTypes.oneOf(['c1', 'g1']),
}

export default Text
