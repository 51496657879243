export const links = {
  // Links in Footer
  footerPage: [
    {
      name: 'projects',
      url: '/projects/',
    },
    {
      name: 'about',
      url: '/about/',
    },
    {
      name: 'work with me',
      url: '/work-with-me/',
    },
    {
      name: 'contact',
      url: '/contact/',
    },
  ],
  // info in Contact
  contactPage: {
    name: 'cy.wtf',
    email: 'studio@cy.wtf',
    blurb: `Currently working on some exciting new React projects. Stay tuned. `,
  },
  aboutBlurb: [
    `I'm a Frontend Engineer & Designer and these are <a href="/projects/">my latest projects</a>.`,
    `If you want to <a href="/work-with-me/">work together</a>, just <a href="/contact/">get in touch</a>.`,
  ],
}
