import React from 'react'
import local from './ArrowUp.module.scss'

export default ({ color = '#F4921F', ...props }) => {
  return (
    <div className={local.container}>
      <svg viewBox="0 0 76 76" fill="none" {...props} aria-labelledby="title-arrow-up">
        <title id="title-arrow-up">arrow right</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.852 44.696c3.717 13.87 17.974 22.102 31.844 18.385 13.87-3.716 22.101-17.973 18.385-31.843-3.717-13.87-17.974-22.101-31.844-18.385-13.87 3.717-22.101 17.973-18.385 31.843zm-4.83 1.294c4.432 16.538 21.43 26.352 37.968 21.92 16.537-4.43 26.351-21.429 21.92-37.966-4.43-16.538-21.43-26.352-37.967-21.92C13.406 12.453 3.592 29.452 8.023 45.99z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.007 34.205L43.333 49.23l-4.33-2.5 6.174-10.695-10.695-6.174 2.5-4.33 15.025 8.674z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.306 40.555l18.353-4.917 1.294 4.83L29.6 45.384l-1.294-4.83z"
          fill={color}
        />
      </svg>
    </div>
  )
}
