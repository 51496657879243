import React from 'react'
import local from './Menu.module.scss'

export default ({ fill = '#fff' }, ...props) => {
  return (
    <div className={local.container}>
      <h3 className={local.brand}>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" fill="none" viewBox="0 0 11 12">
            <title>M</title>
            <desc>menu</desc>
            <path
              fill="#fff"
              d="M10.662.422v11.09H8.434V5.688l-2.74 3.45h-.726l-2.74-3.45v5.826H0V.422h.726L5.331 6.33 9.935.422h.727z"
            />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" fill="none" viewBox="0 0 9 12">
            <title>E</title>
            <desc>menu</desc>
            <path fill="#fff" d="M8.187 2.886h-5.1v2.211h3.747v1.898H3.087v2.756h5.298v2.014H.86V.872h7.328v2.014z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" fill="none" viewBox="0 0 10 12">
            <title>N</title>
            <desc>menu</desc>
            <path fill="#fff" d="M9.545.746v11.09h-.743L2.498 5.104v6.536H.27V.548h.726l6.32 6.684V.746h2.229z" />
          </svg>
        </span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" fill="none" viewBox="0 0 10 12">
            <title>U</title>
            <desc>menu</desc>
            <path
              fill="#fff"
              d="M9.242 6.944c0 .726-.12 1.38-.363 1.964a4.203 4.203 0 01-.974 1.485 4.19 4.19 0 01-1.452.94 5.207 5.207 0 01-1.832.314c-.66 0-1.276-.104-1.848-.313a4.365 4.365 0 01-1.47-.94 4.32 4.32 0 01-.956-1.486C.116 8.325 0 7.67 0 6.944V.557h2.228v6.552c0 .407.06.764.182 1.073.12.308.286.566.495.775.22.21.473.369.759.479.297.099.616.148.957.148.341 0 .655-.05.94-.148.298-.11.551-.27.76-.479.22-.209.39-.467.512-.775.12-.309.181-.666.181-1.073V.557h2.228v6.387z"
            />
          </svg>
        </span>
      </h3>
      <svg className={local.triangle} fill="none" width="61" height="54" viewBox="0 0 61 54" {...props}>
        <path
          fill={fill}
          d="M5.299 50.217h50.403l-9.73-17.02h3.438L61 53.31H0l9.87-17.02h3.553L5.299 50.217zM30.462.212L41.73 19.787h-3.494L30.538 6.354l-8.669 14.98h-3.481L30.462.212z"
        />
      </svg>
    </div>
  )
}
