import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { SiteProvider } from '../context/index'
import { Header, Footer, List } from '../components/index'
import { Wave } from '../components/icons/index'
import { Heading, Text, Blockquote } from '../components/mdx/index'
import PropTypes from 'prop-types'
import '../assets/styles/index.scss'

const Layout = ({ children, bg = 'secondary', mh, headersmall = false, footer = true, logo = true, ...props }) => {
  return (
    <SiteProvider>
      <MDXProvider
        components={{
          h1: props => <Heading {...props} level="1" size="g1" />,
          h2: props => <Heading {...props} level="2" size="g2" />,
          h3: props => <Heading {...props} level="3" size="g3" weight="400" />,
          h4: props => <Heading {...props} level="4" size="g4" />,
          p: props => <Text {...props} size="g1" />,
          ul: props => <List {...props} size="smaller" decoration={true} inline={false} />,
          hr: props => <Wave xsBottom="3rem" xsTop="3rem" mdTop="6rem" mdBottom="6rem" {...props} />,
          blockquote: props => <Blockquote font="Cera" weight="600" {...props} />,
        }}>
        <main className={bg}>
          <header>
            <Header {...props} />
          </header>
          <article className={`content ${mh ? 'min100vh' : ''}`}>{children}</article>
          <footer>
            <Footer />
          </footer>
        </main>
      </MDXProvider>
    </SiteProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
