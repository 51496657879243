import React from 'react'
import styled, { css } from 'styled-components'
import { Text } from './index'

export const Label = props => {
  const {
    align,
    children,
    color,
    background,
    rotate,
    weight,
    padding,
    spacing,
    size = 'smaller',
    font,
    ...rest
  } = props
  return (
    <StyledLabel {...rest} background={background} color={color} padding={padding} rotate={rotate}>
      <StyledText
        size={size}
        align={align}
        transform="uppercase"
        rotate={rotate}
        color={color}
        weight={weight}
        spacing={spacing}
        padding={padding}
        font={font}>
        {children}
      </StyledText>
    </StyledLabel>
  )
}

export default Label

const StyledLabel = styled.div`
  background: ${({ background }) => (background ? background : 'none')};
`

const StyledText = styled(props => <Text {...props} />)`
  ${({ rotate }) =>
    rotate === true &&
    css`
      @media screen and (min-width: 720px) {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        padding: ${({ padding }) => (padding ? padding : '0')};
      }
    `}
`
