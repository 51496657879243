import React from 'react'
import { useSpring, animated } from 'react-spring'
import local from './HeroBrand.module.scss'

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const letter1 = (x, y) => `translate3d(${x / 6}px,${y / 10}px,0)`
const letter2 = (x, y) => `translate3d(${x / 4}px,${y / 4}px,0)`
const letter3 = (x, y) => `translate3d(${x / 12}px,${y / 6}px,0)`
const letter4 = (x, y) => `translate3d(${x / 6}px,${y / 6}px,0)`
const letter5 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`

export default () => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 60, tension: 550, friction: 100 },
  }))
  return (
    <section className={local.section} onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <div className={local.word}>
        <animated.h1
          className={`${local.letter} ${local.letter1}`}
          style={{ transform: props.xy.interpolate(letter1) }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="169" height="179" fill="none" viewBox="0 0 169 179">
            <path
              fill="white"
              d="M92.4.28c16.64 0 31.46 3.813 44.46 11.44 13.173 7.627 23.573 18.027 31.2 31.2l-26.52 18.72c-5.72-9.187-12.653-16.293-20.8-21.32-7.973-5.027-17.42-7.54-28.34-7.54-7.973 0-15.34 1.387-22.1 4.16-6.76 2.773-12.653 6.673-17.68 11.7-4.853 5.027-8.667 11.007-11.44 17.94-2.773 6.933-4.16 14.56-4.16 22.88 0 8.32 1.386 15.947 4.16 22.88 2.773 6.76 6.587 12.653 11.44 17.68 5.027 4.853 10.92 8.667 17.68 11.44 6.76 2.773 14.127 4.16 22.1 4.16 10.92 0 20.367-2.513 28.34-7.54 8.147-5.027 15.08-12.133 20.8-21.32l26.52 18.72c-7.453 13.173-17.767 23.573-30.94 31.2-13.173 7.627-28.167 11.44-44.98 11.44-12.827 0-24.787-2.253-35.88-6.76-11.093-4.507-20.713-10.747-28.86-18.72-8.147-7.973-14.647-17.333-19.5-28.08C3.22 113.64.88 101.853.88 89.2c0-12.653 2.34-24.353 7.02-35.1 4.853-10.92 11.353-20.367 19.5-28.34 8.32-7.973 18.027-14.213 29.12-18.72S79.573.28 92.4.28z"
            />
          </svg>
        </animated.h1>
        <animated.h1
          className={`${local.letter} ${local.letter2}`}
          style={{ transform: props.xy.interpolate(letter2) }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="257" height="179" fill="none" viewBox="0 0 257 179">
            <path
              fill="white"
              d="M256.6 3.4l-68.64 174.72h-11.44L128.68 70.74l-48.1 107.38H69.14L.5 3.4h39l38.22 101.14L122.7.28h11.7l44.98 104.26L217.6 3.4h39z"
            />
          </svg>
        </animated.h1>
        <animated.h1
          className={`${local.letter} ${local.letter3}`}
          style={{ transform: props.xy.interpolate(letter3) }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="146" height="172" fill="none" viewBox="0 0 146 172">
            <path fill="white" d="M145.72 32.12H90.6V172H55.5V32.12H.64V.4h145.08v31.72z" />
          </svg>
        </animated.h1>
        <animated.h1
          className={`${local.letter} ${local.letter4}`}
          style={{ transform: props.xy.interpolate(letter4) }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="154" height="172" fill="none" viewBox="0 0 154 172">
            <path fill="white" d="M94.8 104.4V172H59.7v-67.34L.42.4h39l37.7 68.12L115.08.4h38.74l-59.02 104z" />
          </svg>
        </animated.h1>
        <animated.h1
          className={`${local.letter} ${local.letter5}`}
          style={{ transform: props.xy.interpolate(letter5) }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="114" height="172" fill="none" viewBox="0 0 114 172">
            <path fill="#fff" d="M113.6 32.12H35.86v42.9h63.7v31.72h-63.7V172H.76V.4H113.6v31.72z" />
          </svg>
        </animated.h1>
      </div>
    </section>
  )
}
