export const fragmentShader = `
#define PI 3.14159265358979323846
#define TAU 6.1231853071795864769252867665590 
uniform float time;
uniform float expo;
uniform float red;
uniform float green;
uniform float blue;
varying vec3 offset;
uniform vec2 resolution;
uniform vec3 u_color1;
uniform vec3 u_color2;
uniform vec3 u_color3;

float random( vec2 p) {
    return fract(sin(dot(p.xy,vec2(10.09898,0.211)))*11000.4251);
}

float noise( vec2 P ) {
    vec2 Pi = floor(P);
    vec4 Pf_Pfmin1 = P.xyxy - vec4( Pi, Pi + 1.0 );
    vec4 Pt = vec4( Pi.xy, Pi.xy + 1.0 );
    Pt = Pt - floor(Pt * ( 1.0 / 71.0 )) * 71.0;
    Pt += vec2( 26.0, 161.0 ).xyxy;
    Pt *= Pt;
    Pt = Pt.xzxz * Pt.yyww;
    vec4 hash_x = fract( Pt * ( 1.0 / 951.135664 ) );
    vec4 hash_y = fract( Pt * ( 1.0 / 642.949883 ) );
    vec4 grad_x = hash_x - 0.49999;
    vec4 grad_y = hash_y - 0.49999;
    vec4 end = inversesqrt( grad_x * grad_x + grad_y * grad_y ) * ( grad_x * Pf_Pfmin1.xzxz + grad_y * Pf_Pfmin1.yyww );
    end *= 1.4142135623730950488016887242097;
    vec2 a = Pf_Pfmin1.xy * Pf_Pfmin1.xy * Pf_Pfmin1.xy * (Pf_Pfmin1.xy * (Pf_Pfmin1.xy * 6.0 - 15.0) + 10.0);
    vec4 a2 = vec4( a, vec2( 1.0 - a ) );
    return dot( end, a2.zxzx * a2.wwyy );
}

void main() {
  vec2 uv = gl_FragCoord.xy/resolution.xy;
  float cellWidth = 1.0;
  vec2 cellRowCol = vec2(uv.x / cellWidth, uv.y / cellWidth);
  vec3 u_offset = offset;
  vec4 color = vec4(u_offset.x, u_offset.y, u_offset.z, 1.0);
  vec4 color2 = vec4(0.25 * u_offset.y,0.0,0.25 * u_offset.z, 1.0);
  vec4 map = mix(color, color2, sin(time/5.0 + u_offset.r + TAU + noise(uv/15.0 + time/2.0)/2.0 )/10.0 + cos(time/20.0 + u_offset.b/10.0 - u_offset.g/10.0 + TAU + noise(uv/20.0 + time/5.0)/5.0)/15.0) + random(cellRowCol)/2.0;
  vec4 measure1 = vec4( clamp(vec3(0.33,0.33,0.33) * cos(map.x/6.0 + time/2.0), 0.0, 1.0), 1.0);
  vec4 alphamix2 = vec4( clamp(vec3(0.33,0.33,0.33) * cos(map.y/6.0), 0.0, 1.0), 1.0);
  vec4 measure3 = vec4( clamp(vec3(0.33,0.33,0.33) * cos(map.z/6.0), 0.0, 1.0), 1.0);
  vec4 r1 = vec4( clamp(vec3(0.33,0.33,0.33) * sin(offset.x/4.0 + time/5.0), 0.0, 1.0), 1.0);
  vec4 g2 = vec4( clamp(vec3(0.33,0.33,0.33) * sin(offset.y/4.0 + time/3.0), 0.0, 1.0), 1.0);
  vec4 b3 = vec4( clamp(vec3(0.33,0.33,0.33) * sin(offset.z/4.0), 0.0, 1.0), 1.0);
  vec4 blend = measure1 + alphamix2 + measure3;
  vec4 blend1 = r1 + g2 + b3;
  vec4 col1 = vec4(u_color1, 1.0);
  vec4 col2 = vec4(u_color2, 1.0);
  vec4 col3 = vec4(u_color3, 1.0);
  vec4 mix_1 = mix(col1, col2, blend1 + random(gl_FragCoord.xy*1.0)/7.0);
  vec4 mix_2 = mix(col2, col3, blend);
  gl_FragColor = mix(mix_1, mix_2, clamp(cos(map.x/6.0), 0.0, 1.0));
}

`
