import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const base = {
  align: 'left',
  background: 'none',
  color: 'white',
  line: 1,
  link: 'underline',
  hover: 'none',
  margin: '0',
  max: 'auto',
  padding: 'none',
  size: '1.2rem',
  weight: 'normal',
  spacing: '0',
  transform: 'none',
}

const normal = css`
  font-size: 2.2rem;
  line-height: 1.15;
  @media screen and (min-width: 1025px) {
    font-size: 3.4rem;
    line-height: 1.07;
  }
`

const StyledBlockquote = styled.blockquote`
text-align: ${({ align }) => (align ? align : base.align)};
color: ${({ color }) => (color ? color : base.color)};
background: ${({ background }) => (background ? background : base.background)};
letter-spacing: ${({ spacing }) => (spacing ? spacing : base.spacing)};
margin: ${({ margin }) => (margin ? margin : base.margin)};
max-width: ${({ max }) => (max ? max : base.max)};
text-transform: ${({ transform }) => (transform ? transform : base.transform)};
${({ size }) => size === 'normal' && normal}

${({ link }) =>
  link &&
  css`
    text-decoration: ${link};
  `}
${({ line }) =>
  line &&
  css`
    line-height: ${line}!important;
  `}
& a {
    color: inherit;
    text-decoration: ${({ link }) => (link ? link : base.link)};
}
& a:hover {
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : 'inherit')};;
    text-decoration: ${({ hover }) => (hover ? hover : base.hover)};
}
p {
  ${({ size }) => size === 'normal' && normal}
    font-weight: ${({ weight }) => (weight ? weight : base.weight)} !important;
    font-family: ${({ font }) => (font === 'guggeli' ? 'Guggeli-rounded' : 'Cera')} !important;
}

+ h3 {
  margin-top: 3rem;
  @media screen and (min-width: 720px) {
  margin-top: 6rem;
  }
}

+ p {
  margin-top: 1.5rem;
    @media screen and (min-width: 720px) {
  margin-top: 3rem;
  }
}
`

export const Blockquote = ({
  size = 'normal',
  color,
  spacing,
  hover,
  link,
  line,
  weight,
  background,
  hoverColor,
  font = 'guggeli',
  transform,
  children,
  ...rest
}) => {
  return (
    <StyledBlockquote
      {...rest}
      size={size}
      line={line}
      hoverColor={hoverColor}
      weight={weight}
      spacing={spacing}
      hover={hover}
      background={background}
      color={color}
      font={font}
      transform={transform}
      link={link}>
      {children}
    </StyledBlockquote>
  )
}

Blockquote.propTypes = {
  size: PropTypes.oneOf(['normal']),
}

export default Blockquote
