import React from 'react'
import { Link } from 'gatsby'
import { links } from '../content/site'
import { Text } from '../components'
import local from './Footer.module.scss'

export default () => {
  const { footerPage: data } = links
  return (
    <div className="grid pt2-xs pb3-xs pt4-md pb9-md">
      <div className="row">
        <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset col-6-lg col-3-lg-offset">
          <ul className={local.list}>
            {data &&
              data.length > 0 &&
              data.map((link, index) => (
                <li className={local.item} key={index}>
                  <Link to={link.url}>{link.name}</Link>
                </li>
              ))}
          </ul>
        </div>
        <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset col-6-lg col-3-lg-offset pt1-xs pt3-md">
          <Text size="tinier" link="none" align="center" font="cera">
            {new Date().getFullYear()} © cy.wtf
          </Text>
        </div>
      </div>
    </div>
  )
}
