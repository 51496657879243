import React, { useState } from 'react'

const SiteContext = React.createContext([{}, () => {}])

const SiteProvider = props => {
  const [state, setState] = useState({
    name: 'hello',
    grid: false,
  })
  return <SiteContext.Provider value={[state, setState]}>{props.children}</SiteContext.Provider>
}

export { SiteContext, SiteProvider }
