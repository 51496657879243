import React from 'react'
import { Link } from 'gatsby'
import { List, Text } from './index'

const RenderCat = categories => {
  if (categories && categories.length > 0) {
    return (
      <List spacing="0.5rem" inline={true}>
        {// sort array categories alphabetically first
        categories
          .sort((a, b) => {
            if (a < b) return -1
            else if (a > b) return 1
            return 0
          })
          // then map them
          .map((category, index, array) => (
            <li key={index}>
              <Text size="g2" link="underline" transform="capitalize" weight="normal">
                <Link to={`/projects/category/${category}`}>{category}</Link>
                {index < array.length - 1 ? ',' : ''}
              </Text>
            </li>
          ))}
      </List>
    )
  } else return ''
}

const Categories = ({ categories }) => {
  return RenderCat(categories)
}

export default Categories
