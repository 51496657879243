import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Modal, Text } from './index'
import { Menu } from './icons/index'
import local from './MenuWidget.module.scss'

const MenuWidget = () => {
  const [active, setActive] = useState(false)
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const sections = ['about', 'work-with-me', 'projects', 'contact']
  return (
    <>
      <button onClick={() => setActive(!active)}>
        <Menu />
      </button>

      {active && (
        <Modal onClose={() => setActive(false)}>
          <div className="grid h100">
            <div className="row h100 row-xs-middle">
              <div className="col col-9-xs col-1-xs-offset">
                <ul className={local.list}>
                  {sections &&
                    sections.map((section, index) => {
                      const sectionName = section.replace(/-/g, ' ')
                      return (
                        <li key={index}>
                          <Text
                            tag="span"
                            size="normal"
                            link={url && url.includes(section) ? 'underline' : 'none'}
                            transform="capitalize"
                            align="center"
                            hover="underline">
                            <Link to={section}>{sectionName}</Link>
                          </Text>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default MenuWidget
