import React, { useContext } from 'react'
import styled from 'styled-components'
import { SiteContext } from '../context/index'

export const Toggle = () => {
  const [state, setState] = useContext(SiteContext)
  return (
    <CheckBoxWrapper>
      <CheckBox
        id="checkbox"
        type="checkbox"
        value={state.grid}
        onChange={setState(state => ({ ...state, grid: true }))}
      />
      <CheckBoxLabel htmlFor="checkbox" />
    </CheckBoxWrapper>
  )
}

export default Toggle

const CheckBoxWrapper = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 99;
`
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 6rem;
  height: 3.5rem;
  border-radius: 1.8rem;
  background-color: #444;
  cursor: pointer;
  &::after {
    font-family: 'Cera', sans-serif;
    line-height: 3.5rem;
    text-align: center;
    content: '-';
    color: white;
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    background: red;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    border-radius: 100%;
  }
`
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  width: 6rem;
  height: 3.5rem;

  &:checked + ${CheckBoxLabel} {
    background: #444;
    &::after {
      content: '+';
      display: block;
      width: 3.5rem;
      height: 3.5rem;
      margin-left: 3rem;
      transition: 0.2s;
      background: green;
      border-radius: 100%;
    }
  }
`
