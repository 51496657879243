export const calculateIndex = index => {
  const output = index < 10 ? `0${index + 1}` : index + 1

  return output
}

export const isEven = n => {
  return n % 2 === 0
}

export const isOdd = n => {
  return Math.abs(n % 2) === 1
}
