import React from 'react'
import styled from 'styled-components'

export default ({ fill = '#fff', top, width = '19', height = '34', ...props }) => {
  return (
    <Icon top={top}>
      <svg viewBox="0 0 19 34" width={width} height={height} fill="none" {...props} aria-labelledby="title-arrow-left">
        <title id="title-arrow-left">arrow left</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.893.692l2.5 1.616L5.03 21.433l13.448 9.345-1.698 2.444L.97 22.234 14.893.692z"
          fill={fill}
        />
      </svg>
    </Icon>
  )
}

const Icon = styled.div`
  display: inline-block;
  position: relative;
  z-index: 4;
  svg {
    position: relative;
    top: ${({ top }) => (top ? top : 'auto')};
    transform: scale(0.8);
    @media screen and (min-width: 1024px) {
      transform: scale(1);
    }
  }
`
