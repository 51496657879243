import React from 'react'
import styled, { css } from 'styled-components'

const StyledBlock = styled.div`
display: block;

${({ debug }) =>
  debug &&
  css`
    border: ${`2px dashed ${debug}`};
  `}

${({ xsBottom }) =>
  xsBottom &&
  css`
    margin-bottom: ${xsBottom};
  `}

${({ xsTop }) =>
  xsTop &&
  css`
    margin-top: ${xsTop};
  `}

${({ mdBottom }) =>
  mdBottom &&
  css`
    @media (min-width: 720px) {
      margin-bottom: ${mdBottom};
    }
  `}

${({ mdTop }) =>
  mdTop &&
  css`
    @media (min-width: 720px) {
      margin-top: ${mdTop};
    }
  `}

${({ lgBottom }) =>
  lgBottom &&
  css`
    @media (min-width: 1025px) {
      margin-bottom: ${lgBottom};
    }
  `}

${({ lgTop }) =>
  lgTop &&
  css`
    @media (min-width: 1025px) {
      margin-top: ${lgTop};
    }
  `}
`

export const Block = ({ children, ...props }) => {
  return <StyledBlock {...props}>{children}</StyledBlock>
}

export default Block
