import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'

const base = {
  font: 'Guggeli-rounded',
  accent: '#F59CC5',
  align: 'left',
  background: 'none',
  color: 'white',
  weight: 'bold',
  padding: 'none',
  size: '1.2rem',
  line: 1,
  margin: '0 0 0 0',
  transform: 'none',
}

/* mdx cera */

const c1 = css`
  font-size: 6.4rem;
  line-height: 1.17;
`

const c2 = css`
  font-size: 3.2rem;
  line-height: 1.2;
  @media screen and (min-width: 1024px) {
    font-size: 3.2rem;
    font-weight: bold;
    margin-bottom: 3rem;
  }
`

const c3 = css`
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 400;
  @media screen and (min-width: 1024px) {
    font-size: 2.4rem;
    margin-bottom: 3rem;
  }
`

const c4 = css`
  font-size: 2.4rem;
  line-height: 1.15;
  @media screen and (min-width: 1024px) {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.15;
    margin-bottom: 3rem;
  }
`

/* mdx guggeli */

const g1 = css`
  font-size: 6.4rem;
  line-height: 1;
`

const g2 = css`
  font-size: 2.6rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 720px) {
    font-size: 3.2rem;
    font-weight: bold;
    margin-bottom: 2.6rem; /* fix baseline */
  }
`

const g3 = css`
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 400;
  @media screen and (min-width: 720px) {
    font-size: 2.6rem;
    margin-bottom: 0rem;
  }
`

const g4 = css`
  font-size: 2rem;
  line-height: 1.15;
  font-weight: bold;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 720px) {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.15;
    margin-bottom: 2.6rem; /* fix baseline */
  }
`

const StyledHeading = styled.h1`
background: ${({ background }) => (background ? background : base.background)};
  color: ${({ color, accent }) => (color ? color : accent ? base.accent : base.color)};
  font-family: ${({ font }) => (font === 'guggeli' ? 'Guggeli-rounded' : 'Cera')};
  font-weight: ${({ weight }) => (weight ? weight : base.weight)} !important;
  line-height: ${({ line }) => (line ? line : base.line)};
  margin: ${({ margin }) => (margin ? margin : base.margin)};
  text-align: ${({ align }) => (align ? align : base.align)};
  text-transform: ${({ transform }) => (transform ? transform : base.transform)};
  ${({ size }) => size === 'c1' && c1}
  ${({ size }) => size === 'c2' && c2}
  ${({ size }) => size === 'c3' && c3}
  ${({ size }) => size === 'c4' && c4}
    ${({ size }) => size === 'g1' && g1}
  ${({ size }) => size === 'g2' && g2}
  ${({ size }) => size === 'g3' && g3}
  ${({ size }) => size === 'g4' && g4}
  ${({ z }) =>
    z &&
    css`
      z-index: 10;
      position: relative;
      @media screen and (min-width: 721px) {
        z-index: ${z};
      }
    `}
  & a {
        color: ${base.color};
        text-decoration: underline;
        &:hover {
            text-decoration: underline;
        }
    }

    + h1, + h2, + h3 {
      margin-top: 1.5rem;
      @media screen and (min-width: 721px) {
      margin-top: 2.6rem;
      }
    }

    + p {
      margin-top: 2.6rem;
    }

`

export const Heading = ({ level, size, line, background, z, font = 'guggeli', accent = false, ...rest }) => {
  return (
    <StyledHeading
      as={`h${level}`}
      size={size}
      background={background}
      line={line}
      font={font}
      accent={accent}
      z={z}
      {...rest}
    />
  )
}

Heading.defaultProps = {
  level: 2,
  size: 'g2',
}

Heading.propTypes = {
  size: PropTypes.oneOf(['c1', 'c2', 'c3', 'c4', 'g1', 'g2', 'g3', 'g4']),
}

export default withTheme(Heading)
