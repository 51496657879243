import React from 'react'
import styled from 'styled-components'

const Modal = React.memo(({ children, onClose, background, color, position, ...props }) => {
  return (
    <>
      <StyledModal background={background} color={color} position={position} {...props}>
        <StyledClose className="close" onClick={() => onClose()} onKeyDown={() => onClose()}>
          [ close ]
        </StyledClose>
        {children}
      </StyledModal>
    </>
  )
})

export default Modal

const base = {
  background: 'rgba(56, 28, 219, 0.9)',
  bottom: 0,
  color: 'white',
  index: 100,
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
}

const StyledModal = styled.div`
  background: ${({ background }) => (background ? background : base.background)};
  bottom: ${base.bottom};
  color: ${({ color }) => (color ? color : base.color)};
  left: ${base.left};
  position: ${({ position }) => (position ? position : base.position)};
  right: ${base.right};
  top: ${base.top};
  transition: 350ms ease all;
  z-index: ${base.index};
`

const StyledClose = styled.button`
  position: fixed;
  top: 1rem;
  right: 2rem;
  color: #f4921f;
  font-size: 2.2rem;
  line-height: 120%;
  @media screen and (min-width: 1024px) {
    top: 7rem;
    right: 5rem;
    font-size: 2.8rem;
  }
`
