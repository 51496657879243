import React from 'react'
import styled, { css } from 'styled-components'

export default ({
  fill = '#FDB90E',
  z = '1',
  debug = false,
  width = '566',
  height = '30',
  xsBottom,
  xsTop,
  mdBottom,
  mdTop,
  lgBottom,
  lgTop,
  ...props
}) => {
  return (
    <Container
      z={z}
      debug={debug}
      xsTop={xsTop}
      xsBottom={xsBottom}
      mdTop={mdTop}
      mdBottom={mdBottom}
      lgTop={lgTop}
      lgBottom={lgBottom}
      {...props}>
      <svg fill="none" width={width} height={height} viewBox="0 0 566 30" {...props} role="img">
        <title>wave</title>
        <path
          fill={fill}
          fillRule="evenodd"
          d="M235.184.611l47.056 20.041 46.96-20 46.96 20 46.96-20 46.96 20 46.96-20 48.528 20.668-3.135 7.36L517.04 9.348l-46.96 20-46.96-20-46.96 20-46.96-20-46.96 20-46.868-19.961-.182.086c-1.157.55-2.815 1.333-4.82 2.273a1128.18 1128.18 0 01-14.983 6.9c-5.563 2.508-11.328 5.034-16.047 6.938-2.354.95-4.497 1.766-6.248 2.35-.874.292-1.697.543-2.428.724-.657.163-1.513.342-2.344.342-.867 0-1.711-.192-2.354-.371a24.205 24.205 0 01-2.226-.76c-1.544-.601-3.385-1.432-5.379-2.384-2.868-1.367-6.231-3.07-9.725-4.838-1.356-.686-2.732-1.382-4.107-2.073-4.972-2.498-9.969-4.946-14.264-6.766-2.147-.91-4.055-1.634-5.652-2.125C142.947 9.17 141.896 9 141.36 9c-.498 0-1.415.154-2.853.645-1.388.474-3.029 1.18-4.9 2.082-3.234 1.561-6.882 3.574-10.768 5.719l-1.92 1.058c-4.534 2.495-9.312 5.076-13.749 7.031C102.851 27.438 98.319 29 94.4 29c-3.99 0-8.192-1.608-12.098-3.539-3.538-1.749-7.297-4.006-10.97-6.213l-1.361-.817c-4.185-2.506-8.265-4.892-12.188-6.659C53.83 9.992 50.376 9 47.44 9c-9.996 0-14.905 4.135-20.733 9.056C20.66 23.163 13.737 29 .49 29v-8c10.234 0 15.261-4.163 21.056-9.056l.162-.137C27.683 6.761 34.504 1 47.44 1c4.589 0 9.255 1.508 13.628 3.478 4.403 1.983 8.852 4.597 13.014 7.09l1.357.815c3.726 2.237 7.185 4.313 10.408 5.906C89.528 20.11 92.34 21 94.4 21c2.132 0 5.352-.938 9.544-2.785 4.074-1.795 8.566-4.214 13.118-6.72.626-.344 1.254-.69 1.883-1.038 3.866-2.134 7.736-4.27 11.185-5.934 2.015-.973 3.977-1.83 5.792-2.449C137.687 1.471 139.573 1 141.36 1c1.75 0 3.718.455 5.606 1.036 1.958.603 4.141 1.44 6.419 2.406 4.558 1.93 9.758 4.482 14.736 6.984 1.433.72 2.843 1.434 4.218 2.13 3.458 1.75 6.688 3.384 9.466 4.709 1.936.923 3.567 1.655 4.835 2.148.634.246 1.121.411 1.473.51.099.027.175.045.23.058a7.29 7.29 0 00.395-.088 25.038 25.038 0 001.821-.547c1.525-.509 3.496-1.256 5.787-2.18 4.571-1.845 10.223-4.32 15.753-6.813a1112.89 1112.89 0 0014.874-6.85 1238.328 1238.328 0 006.115-2.89l.464-.22h.001L235.28 5l-1.727-3.608 1.631-.78z"
          clipRule="evenodd"
        />
      </svg>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  z-index: ${({ z }) => (z ? z : 1)}!important;
  transform: scale(0.7);
  svg {
    display: block;
    margin: 0 auto;
  }
  ${({ debug }) =>
    debug &&
    css`
      border: ${`2px dashed ${debug}`};
    `}


    ${({ xsBottom }) =>
      xsBottom &&
      css`
        padding-bottom: ${xsBottom};
      `}

    ${({ xsTop }) =>
      xsTop &&
      css`
        padding-top: ${xsTop};
      `}

    ${({ mdBottom }) =>
      mdBottom &&
      css`
        @media (min-width: 720px) {
          padding-bottom: ${mdBottom};
        }
      `}

    ${({ mdTop }) =>
      mdTop &&
      css`
        @media (min-width: 720px) {
          padding-top: ${mdTop};
        }
      `}

    ${({ lgBottom }) =>
      lgBottom &&
      css`
        @media (min-width: 1025px) {
          padding-bottom: ${lgBottom};
        }
      `}

    ${({ lgTop }) =>
      lgTop &&
      css`
        @media (min-width: 1025px) {
          padding-top: ${lgTop};
        }
      `}
`
