import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Heading, Text, Label } from './index'
import { featuredProject } from '../queries/index' // eslint-disable-line
import { calculateIndex } from '../utils/index'
import { isEven } from '../utils/index'
import { WaveBlue, Wave } from '../components/icons/'
import { links } from '../content/site'
import local from './Landing.module.scss'

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', { offset: 80, updateURL: true })
}

const Content = ({ data }) => {
  const { edges: projects } = data.projects
  const { edges: speaking } = data.speaking
  const { aboutBlurb: about } = links

  return (
    <div className="grid">
      {about && about.length > 1 && (
        <div className="row pb6-xs pb18-lg">
          <div className="col col-12-xs col-0-xs-offset col-1-md col-1-md-offset pb3-xs pb0-lg">
            <Label
              size="normal"
              rotate={true}
              color="#381CDB"
              weight="bold"
              font="cera"
              padding="1rem 0"
              spacing="0.115em">
              about
            </Label>
          </div>

          <div className="col col-12-xs col-0-xs-offset col-8-md col-0-md-offset">
            {about.map((text, index) => (
              <>
                <Text size="medium" font="cera" weight="600" dangerouslySetInnerHTML={{ __html: text }} />
                <br />
              </>
            ))}
          </div>
        </div>
      )}

      <div className="row pb6-xs pb18-lg">
        <div className="col col-12-xs col-0-xs-offset col-9-md col-1-md-offset">
          <Wave />
        </div>
      </div>

      <div className="row pb3-xs pb5-lg">
        <div className="col col-6-xs col-0-xs-offset col-5-md col-1-md-offset">
          <Label
            size="normal"
            rotate={false}
            color="#381CDB"
            weight="bold"
            font="cera"
            padding="1rem 0"
            spacing="0.115em">
            Projects
          </Label>
        </div>
        <div className="col col-6-xs col-0-xs-offset col-4-md col-0-md-offset">
          <Text size="normal" color="#381CDB" font="cera" link="none" hover="underline" weight="600" align="right">
            <Link to="/projects/">All ⌁</Link>
          </Text>
        </div>
      </div>
      {projects.map((project, index) => {
        const { featuredImage, title, headline } = project.node.frontmatter
        const { slug } = project.node.fields
        const even = isEven(index) ? true : false
        return (
          <div className="row pb6-xs pb9-md pb18-lg">
            <div className={`col col-0-xs-offset col-1-md-offset ${even ? 'col-10-xs col-9-md' : 'col-2-xs col-1-md'}`}>
              {even ? (
                <Heading weight="bold" level="3" size="h1" align="left" z="1">
                  {title}
                </Heading>
              ) : (
                <Text
                  color="white"
                  size="smaller"
                  align="left"
                  margin="0.4rem 0 0 0"
                  weight="normal"
                  spacing="-0.05em"
                  z="1">
                  {calculateIndex(index)}
                </Text>
              )}
            </div>
            <div className={`col ${even ? 'col-2-xs col-1-md' : 'col-10-xs col-9-md'}`}>
              {even ? (
                <Text
                  color="white"
                  size="smaller"
                  align="right"
                  margin="0.4rem 0 0 0"
                  weight="normal"
                  spacing="-0.05em"
                  z="1">
                  {calculateIndex(index)}
                </Text>
              ) : (
                <Heading weight="bold" level="3" size="h1" align="right" z="1">
                  {title}
                </Heading>
              )}
            </div>
            <div className={`col col-12-xs col-0-xs-offset col-9-md ${even ? 'col-1-md-offset' : 'col-2-md-offset'}`}>
              <Link to={slug} style={{ color: 'inherit', textDecoration: 'none' }}>
                {featuredImage && (
                  <>
                    <div className={local.styledImage}>
                      <Img fluid={featuredImage.childImageSharp.fluid} />
                    </div>
                  </>
                )}

                {headline && (
                  <Text size="smaller" align={even ? 'left' : 'right'}>
                    {headline}
                  </Text>
                )}
              </Link>
            </div>
          </div>
        )
      })}

      <div className="row pb6-xs pb18-lg">
        <div className="col col-12-xs col-0-xs-offset col-9-md col-2-md-offset">
          <WaveBlue />
        </div>
      </div>

      {speaking && speaking.length > 0 && (
        <>
          <div className="row pb3-xs pb5-lg">
            <div className="col col-12-xs col-0-xs-offset col-9-md col-2-md-offset">
              <Label
                size="normal"
                rotate={false}
                color="#fff"
                weight="bold"
                font="cera"
                padding="1rem 0"
                spacing="0.115em">
                Speaking
              </Label>
            </div>
          </div>

          {speaking.map((result, index) => {
            const {
              frontmatter: { title, headline, featuredImage },
              fields: { slug },
            } = result.node
            return (
              <div className="row pb6-xs pb18-lg" key={index}>
                <div className="col col-12-xs col-0-xs-offset col-9-md col-2-md-offset">
                  <Heading weight="bold" level="3" size="h1" align="left" z="1">
                    {title}
                  </Heading>
                </div>
                <div className="col col-12-xs col-0-xs-offset col-9-md col-2-md-offset">
                  <Link to={slug} style={{ color: 'inherit', textDecoration: 'none' }}>
                    {featuredImage && (
                      <div className={local.styledImage}>
                        <Img fluid={featuredImage.childImageSharp.fluid} />
                      </div>
                    )}

                    {headline && (
                      <Text size="smaller" align="left">
                        {headline}
                      </Text>
                    )}
                  </Link>
                </div>
              </div>
            )
          })}

          <div className="row">
            <div className="col col-12-xs col-0-xs-offset col-9-md col-2-md-offset">
              <WaveBlue />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        projects: allMdx(
          filter: {
            fileAbsolutePath: { regex: "/projects/" }
            frontmatter: { featured: { eq: true }, published: { eq: true } }
          }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              ...featuredProject
              fields {
                slug
              }
            }
          }
          totalCount
        }
        speaking: allMdx(
          filter: {
            fileAbsolutePath: { regex: "/speaking/" }
            frontmatter: { featured: { eq: true }, published: { eq: true } }
          }
        ) {
          edges {
            node {
              id
              ...featuredProject
              fields {
                slug
              }
            }
          }
        }
        blog: allMdx(
          filter: {
            fileAbsolutePath: { regex: "/blog/" }
            frontmatter: { featured: { eq: true }, published: { eq: true } }
          }
        ) {
          edges {
            node {
              id
              ...featuredProject
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={data => <Content data={data} {...props} />}
  />
)
