export const vertexShader = `
#define PI 3.14159265358979323846

varying vec3 offset;
uniform vec2 resolution;
uniform float time;
uniform float low;
uniform float expo;

float Perlin2D( vec2 P ) {
    vec2 Pi = floor(P);
    vec4 Pf_Pfmin1 = P.xyxy - vec4( Pi, Pi + 1.0 );

    vec4 Pt = vec4( Pi.xy, Pi.xy + 1.0 );
    Pt = Pt - floor(Pt * ( 1.0 / 71.0 )) * 71.0;
    Pt += vec2( 26.0, 161.0 ).xyxy;
    Pt *= Pt;
    Pt = Pt.xzxz * Pt.yyww;
    vec4 hash_x = fract( Pt * ( 1.0 / 951.135664 ) );
    vec4 hash_y = fract( Pt * ( 1.0 / 642.949883 ) );

    vec4 grad_x = hash_x - 0.49999;
    vec4 grad_y = hash_y - 0.49999;
    vec4 grad_results = inversesqrt( grad_x * grad_x + grad_y * grad_y ) * ( grad_x * Pf_Pfmin1.xzxz + grad_y * Pf_Pfmin1.yyww );

    grad_results *= 1.4142135623730950488016887242097; 
    vec2 blend = Pf_Pfmin1.xy * Pf_Pfmin1.xy * Pf_Pfmin1.xy * (Pf_Pfmin1.xy * (Pf_Pfmin1.xy * 6.0 - 15.0) + 10.0);
    vec4 blend2 = vec4( blend, vec2( 1.0 - blend ) );
    return dot( grad_results, blend2.zxzx * blend2.wwyy );
}

mat4 rotationX( in float angle ) {
	return mat4(	1.0,		0,			0,			0,
			 		0, 	cos(angle),	-sin(angle),		0,
					0, 	sin(angle),	 cos(angle),		0,
					0, 			0,			  0, 		1);
}

mat4 rotationY( in float angle ) {
return mat4(  cos(angle),   0,    sin(angle), 0,
          0,    1.0,       0, 0,
      -sin(angle),  0,    cos(angle), 0,
          0,    0,        0,  1);
}

mat4 rotationZ( in float angle ) {
	return mat4(	cos(angle),		-sin(angle),	0,	0,
			 		sin(angle),		cos(angle),		0,	0,
							0,				0,		1,	0,
							0,				0,		0,	1);
}

void main() {

  float speed = 0.05;
  float density = 0.025;
  float power = 20.0;
  float amber = 2.0 * PI; 

  float cellWidth = 10.0;
  vec2 cellRowCol = vec2(uv.x / cellWidth, uv.y / cellWidth);

  mat4 rotExp = rotationY( (low * 0.4) + position.y/(30.0 - expo/amber));

  float centerX = 10.0;
  float centerZ = 0.0;

  offset = normalize( vec3( 0.0 ) - position + position.x/5.0 ) * ( power * (cos( speed * time + position.z/15.0 ) + sin(speed * time + position.y/50.0 * (density * sin(time/2.0)))) );

  vec3 coordinates = position + vec3( offset.x, 0.0, offset.y);
  
  gl_Position = projectionMatrix * (modelViewMatrix * (rotExp)) * vec4(coordinates, 1.0);
}`
