import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const base = {
  align: 'left',
  background: 'none',
  color: 'white',
  line: 1,
  link: 'underline',
  hover: 'none',
  margin: 'none',
  max: 'auto',
  padding: 'none',
  size: '1.2rem',
  weight: 'normal',
  spacing: '0',
  transform: 'none',
}

// font size for cera
const c1 = css`
  font-size: 2rem;
  line-height: 1.15;
  @media screen and (min-width: 1025px) {
    font-size: 2rem;
  }
`

// font size for guggeli
const g1 = css`
  font-size: 1.8rem;
  line-height: 1.15;
  @media screen and (min-width: 1025px) {
    font-size: 2.2rem;
  }
`

const g2 = css`
  font-size: 1.8rem;
  line-height: 1.15;
  @media screen and (min-width: 1025px) {
    font-size: 2.4rem;
  }
`

/* old styles */

/* ui - sections */
const large = css`
  font-size: 5rem;
  line-height: 1.11;
  @media screen and (min-width: 1025px) {
    font-size: 8rem;
  }
`

const medium = css`
  font-size: 3.4rem;
  line-height: 1.12;
  @media screen and (min-width: 1025px) {
    font-size: 5.4rem;
  }
`

const normal = css`
  font-size: 2.8rem;
  line-height: 1.12;
  @media screen and (min-width: 1025px) {
    font-size: 4.2rem;
  }
`

const smaller = css`
  font-size: 2rem;
  line-height: 1.12;
  @media screen and (min-width: 1025px) {
    font-size: 3.2rem;
  }
`

const tiny = css`
  font-size: 1.8rem;
  line-height: 1.12;
  @media screen and (min-width: 1025px) {
    font-size: 2.4rem;
  }
`

const tinier = css`
  font-size: 1.4rem;
  line-height: 1.12;
  @media screen and (min-width: 1025px) {
    font-size: 2.2rem;
  }
`

const StyledText = styled.p`
	text-align: ${({ align }) => (align ? align : base.align)};
	color: ${({ color }) => (color ? color : base.color)};
	background: ${({ background }) => (background ? background : base.background)};
  letter-spacing: ${({ spacing }) => (spacing ? spacing : base.spacing)};
  margin: ${({ margin }) => (margin ? margin : base.margin)};
  font-family: ${({ font }) => (font === 'guggeli' ? 'Guggeli-rounded' : 'Cera')};
	font-weight: ${({ weight }) => (weight ? weight : base.weight)} !important;
	max-width: ${({ max }) => (max ? max : base.max)};
  text-transform: ${({ transform }) => (transform ? transform : base.transform)};
	${({ size }) => size === 'medium' && medium}
	${({ size }) => size === 'normal' && normal}
	${({ size }) => size === 'large' && large}
  ${({ size }) => size === 'smaller' && smaller}
    ${({ size }) => size === 'tiny' && tiny}
    ${({ size }) => size === 'tinier' && tinier}
    ${({ size }) => size === 'g1' && g1}
    ${({ size }) => size === 'g2' && g2}
    ${({ size }) => size === 'c1' && c1}
	${({ link }) =>
    link &&
    css`
      text-decoration: ${link};
    `}
    ${({ line }) =>
      line &&
      css`
        line-height: ${line}!important;
      `}
    & a {
        color: ${({ color }) => (color ? color : 'inherit')};
        text-decoration: ${({ link }) => (link ? link : base.link)};
    }
    & a:hover {
        color: ${({ hoverColor }) => (hoverColor ? hoverColor : 'inherit')};;
        text-decoration: ${({ hover }) => (hover ? hover : base.hover)};
    }
    + h2, + h3, + h4, + h5, + blockquote, + code {
      margin-top: 3.2rem;
      @media screen and (min-width: 1025px) {
        margin-top: 5rem;
      }
    }
`

export const Text = ({
  size = 'g1',
  tag = 'p',
  align,
  color,
  spacing,
  hover,
  link,
  line,
  weight,
  hoverColor,
  font = 'guggeli',
  transform,
  ...rest
}) => {
  return (
    <StyledText
      {...rest}
      as={tag}
      size={size}
      align={align}
      line={line}
      hoverColor={hoverColor}
      weight={weight}
      spacing={spacing}
      hover={hover}
      color={color}
      font={font}
      transform={transform}
      link={link}
    />
  )
}

Text.propTypes = {
  size: PropTypes.oneOf(['medium', 'normal', 'large', 'smaller', 'tiny', 'tinier', 'g1', 'c1', 'g2']),
}

export default Text
