import React from 'react'

function Link({ fill = '#fff', width = '19', ...props }) {
  return (
    <svg width={width} viewBox="0 0 22 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.645 8.873L6.5 1.866l1-1.732 13.855 7.993-7.483 13.362-1.745-.978 6.518-11.638z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.308 10.951l-18.5 6-.617-1.902 18.5-6 .617 1.902z"
        fill={fill}
      />
    </svg>
  )
}

export default Link
