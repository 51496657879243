import React from 'react'
import { Link } from 'gatsby'
import local from './Logo.module.scss'

export default ({ fill = '#fff' }) => (
  <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
    <h2 className={local.brand}>
      <span>
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="30" height="21" fill="none" viewBox="0 0 30 21">
          <title>W</title>
          <desc>logo</desc>
          <path
            fill={fill}
            d="M30 .784l-8.04 19.914h-1.34L15.014 8.46 9.381 20.698H8.04L0 .784h4.569l4.477 11.528L14.315.429h1.37l5.27 11.883L25.431.784H30z"
          />
        </svg>
      </span>
      <span>
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
          <title>T</title>
          <desc>logo</desc>
          <path fill={fill} d="M20.298 3.873h-7.952v17.08H8.06V3.873H.14V0h20.158v3.873z" />
        </svg>
      </span>
      <span>
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="16" height="21" fill="none" viewBox="0 0 16 21">
          <title>F</title>
          <desc>logo</desc>
          <path fill={fill} d="M16 3.92H4.228v5.238h10.08v3.873H4.229v7.968H0V.046h16v3.873z" />
        </svg>
      </span>
    </h2>
  </Link>
)
