module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/vercel/workpath0/node_modules/gatsby-remark-unwrap-images","id":"971fabea-81a8-5611-933a-539f23b623a8","name":"gatsby-remark-unwrap-images","version":"1.0.1","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/vercel/workpath0/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.42","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"gatsbyRemarkPlugins":["gatsby-remark-unwrap-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":1120,"linkImagesToOriginal":false,"withWebp":true,"loading":"lazy","showCaptions":false,"markdownCaptions":false}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://stats.cy.wtf","siteUrl":"https://cy.wtf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-2020","short_name":"2020","start_url":"/","background_color":"#222222","theme_color":"#ffffff","display":"minimal-ui","icon":"src/assets/images/gatsby-icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
