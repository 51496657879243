import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from '../components/grid'
import { Contact } from './icons/index'
import { links } from '../content/site'
import { Heading, Text, Modal } from '../components/index'
import { ArrowUp } from '../components/icons/index'

export default () => {
  const [active, setActive] = useState(false)
  return (
    <>
      <Button onClick={() => setActive(true)} onKeyDown={() => setActive(true)}>
        <Contact onClick={() => setActive(true)} onKeyDown={() => setActive(true)} />
      </Button>
      {active && (
        <Modal>
          <StyledRow xsTop="4rem" xsBottom="4rem" lgTop="8rem" lgBottom="8rem">
            <Col xs={2} xsOffset={10}>
              <StyledClose className="close" onClick={() => setActive(false)} onKeyDown={() => setActive(false)}>
                <Text size="normal" color="#f4921f">
                  [ close ]
                </Text>
              </StyledClose>
            </Col>
            <Col xs={10} xsOffset={1}>
              <Heading size="large" dangerouslySetInnerHTML={{ __html: links.contactPage.name }} />
              <Text size="medium" font="cera" dangerouslySetInnerHTML={{ __html: links.contactPage.email }} />
            </Col>
            <Col xs={10} xsOffset={1} md={8} lg={8}>
              <Text
                size="medium"
                font="cera"
                weight="600"
                className="text"
                dangerouslySetInnerHTML={{ __html: links.contactPage.blurb }}
              />
            </Col>
            <Col xs={10} xsOffset={1} lg={10}>
              <Links>
                <Text size="medium" color="#f4921f" link="none" hover="underline" line="1">
                  <Link to="/about">
                    About <StyledIcon />
                  </Link>
                </Text>
                <Text size="medium" color="#f4921f" link="none" hover="underline" line="1">
                  <Link to="/work-with-me/">
                    Work with me <StyledIcon />
                  </Link>
                </Text>
              </Links>
            </Col>
          </StyledRow>
        </Modal>
      )}
    </>
  )
}

const StyledRow = styled(props => <Row {...props} />)`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const StyledIcon = styled(ArrowUp)`
  position: relative;
  top: 2.4rem;
`

const Links = styled.div`
  /* fix spacing between two paragraphs */
  p + p {
    margin-top: 0 !important;
  }
`

const StyledClose = styled.button`
  margin-right: auto;
  display: block;
`

const Button = styled.button`
  width: 90%;
  height: auto;
  position: relative;
  z-index: 12;
  padding: 0;
  svg {
    width: 100%;
  }
`
