import React, { useMemo, useRef, useEffect } from 'react'
import { useThree, useFrame } from 'react-three-fiber'
import * as THREE from 'three'
import { fragmentShader } from '../../../assets/shaders/02/fragmentShader'
import { vertexShader } from '../../../assets/shaders/02/vertexShader'

function CoolShape() {
  const { viewport, size, camera } = useThree()

  // change camera FOV on mobile
  useEffect(() => {
    if (size.width < 700 && camera.fov < 70) {
      camera.fov = 80
      camera.updateProjectionMatrix()
    }
  }, [size, camera])

  // (180deg, #fea2c4 0%, #351adb 100%);
  const ref = useRef()
  useFrame(() => {
    ref.current.material.uniforms.time.value += 0.05
    ref.current.material.uniforms.faktor.value += 0.0314159265358979323846
    ref.current.material.uniforms.low.value += 0.0314159265358979323846
  })
  const data = useMemo(
    () => ({
      uniforms: {
        low: { type: 'f', value: 0.1 },
        faktor: { type: 'f', value: 0.2 },
        time: { type: 'f', value: 0.2 },
        expo: { type: 'f', value: 0 },
        mouseX: { type: 'f', value: 0 },
        mouseY: { type: 'f', value: 0 },
        red: { type: 'f', value: 0.0 },
        green: { type: 'f', value: 0.0 },
        blue: { type: 'f', value: 1.5 },
        u_color1: { type: 'v3', value: new THREE.Color(0x8254d3) },
        u_color2: { type: 'v3', value: new THREE.Color(0x351adb) },
        u_color3: { type: 'v3', value: new THREE.Color(0xfea2c4) },
        resolution: {
          type: 'v2',
          value: new THREE.Vector2(viewport.width, viewport.height),
        },
      },
      vertexShader,
      fragmentShader,
    }),
    [viewport.width, viewport.height]
  )

  return (
    <mesh ref={ref}>
      <torusBufferGeometry attach="geometry" args={[18, 18, 150, 150]} />
      <shaderMaterial attach="material" {...data} />
    </mesh>
  )
}

export default () => {
  return (
    <group>
      <CoolShape />
    </group>
  )
}
