import React from 'react'
import { MenuWidget, Logo } from './index'

export default () => {
  return (
    <div className="grid">
      <div className="row row-xs-middle row-xs-between">
        <div className="col col-3-xs col-2-md col-1-lg">
          <Logo />
        </div>
        <div className="col col-3-xs col-2-md col-1-lg">
          <MenuWidget />
        </div>
      </div>
    </div>
  )
}
