import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import config from './config'

const Grid = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: ${p => config(p).outerMargin + 'rem'};
  padding-left: ${p => config(p).outerMargin + 'rem'};
  max-width: 84%;
  @media (min-width: 720px) {
    max-width: 92.5%;
  }
  

  /* add debug lines to all grids
    turn on/off in config.js
    */
  ${p =>
    config(p).gridDebug &&
    css`
      border: 2px dotted cyan;
    `}

  /* optional prop user can turn on/off and indicate color
    to debug specific column
    */
  ${({ debug }) =>
    debug &&
    css`
      border: ${`2px dashed ${debug}`};
    `}

      ${({ xsBottom }) =>
        xsBottom &&
        css`
          padding-bottom: ${xsBottom};
        `}

    ${({ xsTop }) =>
      xsTop &&
      css`
        padding-top: ${xsTop};
      `}

    ${({ mdBottom }) =>
      mdBottom &&
      css`
        @media (min-width: 720px) {
          padding-bottom: ${mdBottom};
        }
      `}

    ${({ mdTop }) =>
      mdTop &&
      css`
        @media (min-width: 720px) {
          padding-top: ${mdTop};
        }
      `}

    ${({ lgBottom }) =>
      lgBottom &&
      css`
        @media (min-width: 1025px) {
          padding-bottom: ${lgBottom};
        }
      `}

    ${({ lgTop }) =>
      lgTop &&
      css`
        @media (min-width: 1025px) {
          padding-top: ${lgTop};
        }
      `}

`

Grid.displayName = 'Grid'

Grid.propTypes = {
  fluid: PropTypes.bool,
  children: PropTypes.node,
}

export default Grid

/*

original example

${p =>
    !p.fluid &&
    css`
      ${DIMENSION_NAMES.map(
        t =>
          config(p).container[t] &&
          config(p).media[t]`
        max-width: ${p => config(p).container[t]}rem;
      `
      )}
    `}
  }

  */
