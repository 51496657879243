import React from 'react'
import styled, { css } from 'styled-components'

const base = {
  style: 'none', // circle, square, etc
  spacing: '2rem',
}

const StyledList = styled.ul`
  + h2,
  + h3,
  + h4 {
    margin-top: 3rem;
    @media screen and (min-width: 1025px) {
      margin-top: 5.5rem;
    }
  }

  ${'' /* // if inline is false, display ul as a block */}
  ${({ inline }) =>
    inline === true &&
    css`
      display: flex;
      flex-wrap: wrap;
      list-style: none;
    `}

  li {
    font-family: ${({ font }) => (font === 'guggeli' ? 'Guggeli-rounded' : 'Cera')} !important;
    text-transform: ${({ transform }) => (transform ? transform : 'none')} !important;
    margin-left: 'none';
    list-style: none;
    ${({ decoration }) =>
      decoration === true &&
      css`
        display: flex;
        &:before {
          content: '⌁';
          font-weight: bold;
          font-size: 2.4rem;
          color: #fea2c4;
          flex: 0 0 2.4rem;
          margin-right: 0.8rem;
        }
      `}
  }
  li + li {
    ${({ inline, spacing }) =>
      inline === true &&
      css`
        margin-left: ${({ spacing }) => (spacing ? spacing : base.spacing)};
      `}

    ${({ inline, spacing }) =>
      inline !== true &&
      css`
        margin-top: ${({ spacing }) => (spacing ? spacing : '1.5rem')};
        @media screen and (min-width: 1025px) {
          // make sure the list doesn't occupy the entire container
          max-width: 74.09%;
        }
      `}
  }
  li a {
    color: inherit;
    text-decoration: inherit;
  }
  li a:hover {
    text-decoration: underline;
  }

  ${({ size }) =>
    size === 'smaller' &&
    css`
      li {
        font-size: 2rem;
        line-height: 1.12;
        @media screen and (min-width: 1025px) {
          font-size: 3.2rem;
        }
      }
    `}

  ${({ debug }) =>
    debug &&
    css`
      border: ${`3px solid ${debug}`};
    `}
`

export const List = ({
  decoration = 'none',
  debug,
  size,
  font,
  inline = false,
  spacing,
  transform,
  children,
  ...rest
}) => {
  return (
    <StyledList
      decoration={decoration}
      spacing={spacing}
      font={font}
      transform={transform}
      size={size}
      debug={debug}
      inline={inline}
      {...rest}>
      {children}
    </StyledList>
  )
}

export default List
