import React from 'react'
import styled from 'styled-components'

export default ({ color = '#fff', align = 'left', height = 66, width = 37, fill = 'none', stroke = 6, ...props }) => {
  return (
    <Container>
      <svg fill="none" viewBox="0 0 37 66" height={height} width={width} {...props} aria-labelledby="title-arrow-right">
        <title id="title-arrow-right">arrow right</title>
        <path
          fill={color}
          fillRule="evenodd"
          d="M8.138.372L3.1 3.628l24.925 38.564L.907 61.036l3.424 4.928 31.882-22.156L8.138.372z"
          clipRule="evenodd"
        />
      </svg>
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
  transform: scale(0.6);
  text-align: ${({ align }) => (align ? align : 'left')};
  @media screen and (min-width: 1025px) {
    transform: scale(1);
  }
`
