import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'

const base = {
  font: 'Guggeli-rounded',
  accent: '#F59CC5',
  align: 'left',
  background: 'none',
  color: 'white',
  weight: 'bold',
  padding: 'none',
  size: '1.2rem',
  line: 1,
  margin: '0 0 0 0',
  transform: 'none',
}

/* new styles */

// section title
const h1 = css`
  font-size: 2.6rem;
  font-weight: bold;
  @media screen and (min-width: 1024px) {
    font-size: 6.4rem;
    line-height: 1;
    font-weight: bold;
  }
`

// project
const h2x = css`
  font-size: 2.6rem;
  line-height: 1.2;
  @media screen and (min-width: 1024px) {
    font-size: 3.8rem;
    font-weight: bold;
  }
`

// project
const h2 = css`
  font-size: 3.2rem;
  line-height: 1.2;
  @media screen and (min-width: 1024px) {
    font-size: 3.2rem;
    font-weight: bold;
    margin-bottom: 2.6rem; /* fix baseline */
  }
`

const h3 = css`
  font-size: 2.6rem;
  line-height: 1.2;
  font-weight: 400;
  @media screen and (min-width: 1024px) {
    font-size: 2.6rem;
    margin-bottom: 0rem;
  }
`

const h4 = css`
  font-size: 2.4rem;
  line-height: 1.15;
  @media screen and (min-width: 1024px) {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.15;
    margin-bottom: 2.6rem; /* fix baseline */
  }
`

/*

old headers

*/

// section - title
const xlarge = css`
  font-size: 46px;
  line-height: 1.021;
  font-weight: bold;
`

// project big header
const large = css`
  font-size: 2.6rem;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 0;
  @media screen and (min-width: 721px) {
    font-size: 4.5rem;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 6.4rem;
    margin-bottom: 2rem;
  }
`

// project name
const medium = css`
  font-size: 2.4rem;
  line-height: 1.16;
  font-weight: bold;
  letter-spacing: -0.015em;
  @media screen and (min-width: 1025px) {
    font-size: 5.4rem;
    line-height: 1.16;
    font-weight: bold;
    letter-spacing: -0.015em;
  }
`

// markdown header
const normal = css`
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: -0.015em;
  @media screen and (min-width: 1025px) {
    font-size: 3.8rem;
  }
`

// markdown default header
const smaller = css`
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: -0.015em;
  @media screen and (min-width: 1025px) {
    font-size: 3.2rem;
  }
`

const StyledHeading = styled.h1`
background: ${({ background }) => (background ? background : base.background)};
  color: ${({ color, accent }) => (color ? color : accent ? base.accent : base.color)};
  font-family: ${({ font }) => (font === 'guggeli' ? 'Guggeli-rounded' : 'Cera')};
  font-weight: ${({ weight }) => (weight ? weight : base.weight)} !important;
  line-height: ${({ line }) => (line ? line : base.line)};
  margin: ${({ margin }) => (margin ? margin : base.margin)};
  text-align: ${({ align }) => (align ? align : base.align)};
  text-transform: ${({ transform }) => (transform ? transform : base.transform)};
  ${({ size }) => size === 'medium' && medium}
    ${({ size }) => size === 'h1' && h1}
      
      ${({ size }) => size === 'h2' && h2}
      ${({ size }) => size === 'h2x' && h2x}
        ${({ size }) => size === 'h3' && h3}
          ${({ size }) => size === 'h4' && h4}
  ${({ size }) => size === 'large' && large}
  ${({ size }) => size === 'xlarge' && xlarge}
  ${({ size }) => size === 'smaller' && smaller}
  ${({ size }) => size === 'normal' && normal}
  ${({ z }) =>
    z &&
    css`
      z-index: 10;
      position: relative;
      @media screen and (min-width: 1025px) {
        z-index: ${z};
      }
    `}
  & a {
        color: ${base.color};
        text-decoration: none;
        ${'' /* cursor: pointer; */}
        &:hover {
            text-decoration: underline;
        }
    }

    + p, + ul, + blockquote, + code, + iframe, + h3,  + h4 {
      margin-top: 2.4rem;
      @media screen and (min-width: 1025px) {
        margin-top: 5rem;
      }
    }

`

export const Heading = ({ level, size, line, background, z, font = 'guggeli', accent = false, ...rest }) => {
  return (
    <StyledHeading
      as={`h${level}`}
      size={size}
      background={background}
      line={line}
      font={font}
      accent={accent}
      z={z}
      {...rest}
    />
  )
}

Heading.defaultProps = {
  level: 2,
  size: 'h2',
}

Heading.propTypes = {
  size: PropTypes.oneOf(['medium', 'large', 'xlarge', 'smaller', 'normal', 'h1', 'h2', 'h3', 'h4', 'h2x']),
}

export default withTheme(Heading)
