import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({ description, lang, slug, title, image }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
            social {
              image
              twitter
              facebookId
            }
          }
        }
      }
    `}
    render={({ site: { siteMetadata: seo } }) => {
      const meta_title = title ? `${title} | ${seo.title}` : seo.title
      const meta_description = description || seo.description
      const meta_image = image ? `${seo.siteUrl}/${image}` : `${seo.siteUrl}/${seo.social.image}`
      const meta_url = seo.siteUrl

      return (
        <Helmet>
          {/* General tags */}
          <html lang="en" amp />
          <title>{meta_title}</title>
          <meta name="description" content={meta_description} />
          <meta name="image" content={meta_image} />
          <link rel="canonical" href={meta_url} />

          {/* OpenGraph tags */}
          <meta property="og:url" content={meta_url} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta_title} />
          <meta property="og:description" content={meta_description} />
          <meta property="og:image" content={meta_image} />
          <meta property="fb:app_id" content={seo.social.facebookId} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={seo.social.twitter} />
          <meta name="twitter:title" content={meta_title} />
          <meta name="twitter:description" content={meta_description} />
          <meta name="twitter:image" content={meta_image} />
        </Helmet>
      )
    }}
  />
)
export default SEO
