import React from 'react'
import { Link } from 'gatsby'
import { Categories, Heading, Text } from './index'
import { calculateIndex } from '../utils/index'

const Project = ({ data: project, index }) => {
  const {
    frontmatter: { headline, title, categories },
    fields: { slug },
  } = project
  return (
    <div className="row pb3-xs pb3-md">
      <div className="col col-2-xs col-0-xs-offset col-1-md col-2-md-offset">
        <Text color="white" size="smaller" align="center" margin="0.4rem 0 0 0" weight="normal" spacing="-0.05em">
          {calculateIndex(index)}
        </Text>
      </div>
      <div className="col col-10-xs col-0-xs-offset col-7-md col-0-md-offset">
        <div className="row row-xs-middle">
          <div className="col col-auto">
            <Heading level="2" size="h2x" margin="0 0 0.4rem 0">
              <Link to={slug}>{title}</Link>
            </Heading>
          </div>
        </div>
        <div className="row row-xs-center">
          <div className="col col-auto">
            <Text size="g1" dangerouslySetInnerHTML={{ __html: headline }} margin="0 0 0.5rem 0" />
          </div>
        </div>
        <div className="row row-xs-center">
          <div className="col col-auto">
            <Categories categories={categories} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project
